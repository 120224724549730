.main-container {
  min-height: 220px;

  h1,
  p {
    text-align: center;
  }

  .content-search {
    float: none !important;
    margin: 20px auto 40px !important;
  }

  .error-content {
    width: auto;

    #search-box-wrapper {
      .form-item.form-item-query.form-type-textfield {
        float: left;
        margin-right: 5px;
        width: 75%;

        input {
          @media (--screen-sm-max) {
            height: auto !important;
          }
        }

        @media (--screen-sm-min) {
          margin-right: 10px;
          width: 80%;
        }
      }

      #bn-site-search-button {
        display: block !important;
        padding-left: 3px;
        padding-top: 6px;
        width: 20%;

        @media (--screen-sm-min) {
          padding: 12px 16px 8px 9px;
          width: 15%;
        }
      }

      @media (--screen-sm-max) {
        max-width: 400px;
        width: auto;
      }

      @media (--screen-sm-min) and (--screen-md-max) {
        width: 500px;
      }
    }
  }
}

.error {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  &__info {
    max-width: 700px;

    form {
      .bn-search-input {
        background: #f9f9f9 0 0 no-repeat padding-box;
        border: 1px solid #e2e2e2;
        border-radius: 4px 0 0 4px;
        opacity: 1;

        &:focus {
          border-color: var(--bn-orange);
          box-shadow: none;
        }

        &::placeholder {
          color: #b1b1b1;
        }
      }

      button {
        background: var(--bn-orange);
        border-radius: 0 4px 4px 0;
        width: 50px;

        i {
          color: #050301;
          width: 100%;
        }
      }

      @media (--screen-md-min) {
        max-width: 500px;
      }
    }

    @media (--screen-md-max) {
      max-width: 300px;
      text-align: center;
    }
  }

  &__title {
    color: var(--bn-orange);
    font-size: 6em;
    font-weight: var(--font-medium);
    margin-bottom: 15px;

    @media (--screen-md-max) {
      font-size: 2em;
    }
  }

  &__description {
    font-size: 2.25em;
    font-weight: var(--font-medium);
    line-height: 1.25;
    margin-bottom: 10px;

    @media (--screen-md-max) {
      font-size: 1em;
      font-weight: var(--font-regular);
      margin-bottom: 15px;
    }
  }

  &__code {
    font-size: 1.188em;
    margin-bottom: 20px;

    @media (--screen-md-max) {
      font-size: 0.75em;
      font-weight: var(--font-medium);
      margin-bottom: 40px;
    }
  }

  &__img {
    height: auto;
    max-width: 460px;
    width: 100%;

    @media (--screen-md-max) {
      margin-bottom: 20px;
      max-width: 180px;
    }
  }

  @media (--screen-md-max) {
    flex-direction: column;
  }
}
